import { store } from "@/store/store";
import { recurso_data } from "@/shared/dtos/recurso_data";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "recurso_dataModule",
  store,
  dynamic: true,
})
class recurso_dataModule extends VuexModule {
  public recurso_datas: recurso_data[] = [];
  public recurso_data: recurso_data = new recurso_data();

  @Action({ commit: "onGetrecurso_datas" })
  public async getrecurso_datas() {
    return await ssmHttpService.get(API.recurso_data);
  }

  @Action({ commit: "onGetrecurso_data" })
  public async getrecurso_data(id: any) {
    return await ssmHttpService.get(API.recurso_data + "/" + id);
  }

  @Action
  public async guardarrecurso_data(recurso_data: recurso_data) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.recurso_data, recurso_data.toJson());
    this.getrecurso_datas();
  }

  @Action
  public async modificarrecurso_data(recurso_data: recurso_data) {
    await ssmHttpService.put(
      API.recurso_data + "/" + recurso_data.id,
      recurso_data
    );
    this.getrecurso_datas();
  }

  @Action
  public async eliminarrecurso_data(recurso_data: recurso_data) {
    await ssmHttpService.delete(
      API.recurso_data + "/" + recurso_data.id,
      null,
      false
    );
    this.getrecurso_datas();
  }

  @Mutation
  public onGetrecurso_datas(res: recurso_data[]) {
    this.recurso_datas = res ? res.map((x) => new recurso_data(x)) : [];
  }

  @Mutation
  public onGetrecurso_data(res: recurso_data) {
    this.recurso_data = new recurso_data(res);
  }
}
export default getModule(recurso_dataModule);
