








































import { RouterNames } from "@/router/routernames";
import { BaseDto } from "@/shared/dtos/base-dto";
import dispositivoModule from "@/store/modules/dispositivo-module";
import recurso_dataModule from "@/store/modules/recurso_data-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class RecursosFicha extends Vue {
  //Donde se tiene que ver los datos de los recursos con los dispositivos vinculados
  //sensores vinculados
  async created() {
    recurso_dataModule.getrecurso_data(this.$route.params.id);
    await dispositivoModule.getdispositivoByRecurso(this.$route.params.id);
  }

  editar(obj: BaseDto) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.dispositivosformulario,
      params: { id: id },
    });
  }

  get data_source() {
    return recurso_dataModule.recurso_data;
  }
  get data_source_dispositivo() {
    return dispositivoModule.dispositivos;
  }
}
